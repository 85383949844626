
import styled from 'styled-components';
import {BasicColor} from '../../Components/GlobalStyle';

export const Container = styled.div`
    padding: 20px;

    
`

